.footer {
  color: #ffffff;
  height: 300px;
  background-color: #121212;
  z-index: 30;
  display: flex;
}

.footer .container {
  width: calc(100vw - (5.33333vw * 2));
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* 要素間の空間を均等に配分 */
  height: 100%; /* コンテナの高さをフッターの高さに合わせる */
}

/* ヘッダー:サイト名（企業）*/
.footer .siteBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 85px;
  padding-top: 20px;
}

.footer .site {
  display: flex;
  align-items: center;
}

/* PC用設定ヘッダー　タイトルロゴ*/
.footer .site img {
  width: 85px;
  margin-right: 18px;
}

.footer .site .corp {
  font-size: 48px;
}

.footer .nav ul {
  font-size: 24px;
  display: flex;
  list-style-type: none;
  gap: 40px;
}

.address {
  font-size: 14px;
}

.footer .twitterLink {
  font-size: 16px; /* フォントサイズの調整 */
}

.footer p.copyLight {
  font-size: 12px;
  align-items: flex-end;
}

@media (max-width: 899px) {
  .footer {
    height: 230px;
  }

  /* PC用設定ヘッダー　タイトルロゴ*/
  .footer .site img {
    width: 50px;
    margin-right: 18px;
  }

  .footer .site .corp {
    font-size: 30px;
  }

  .footer .nav {
    font-size: 24px;
    display: none;
  }

  .footer p.copyLight {
    font-size: 12px;
  }

  .footer .twitterLink {
    font-size: 12px; /* フォントサイズの調整 */
  }
}
