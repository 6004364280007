.moonEulaPageContainer {
  background-color: #000707;
  width: 100%;
}

.moonEulaPageContainer .header {
  position: relative;
}

.moonEulaPageContainer p {
  width: calc(100vw - (5.33333vw * 2));
  margin: 0 auto;
  color: #ffffff;
  font-size: 20px;
}

.moonEulaPageContainer p.title {
  font-size: 26px;
  margin-bottom: 30px;
  margin-top: 30px;
}

.moonEulaPageContainer p.desc {
  font-size: 20px;
}

.moonEulaPageContainer .footer {
  background-color: #121212;
}
