@media (min-width: 851px) {
  .container {
    width: 90vw;
    //  height: 500px;
    //  background-color: aqua;
    display: flex;
    justify-content: space-between;
    padding: 20px;
    //    color: #bbbbbb;
  }

  .container img {
    width: 40vw;
  }

  .container .textBlock {
    margin-left: 10px;
    width: 50%;
    display: flex; /* Flex コンテナに設定 */
    flex-direction: column; /* 縦方向に要素を並べる */
  }
  .container h2 {
    font-size: 50px;
    text-align: center;
  }

  .pBlock p {
    font-size: 20px;
    text-align: center;
  }

  .pBlock1 {
    padding-top: 20px;
  }

  .pBlock2 {
    padding-bottom: 20px;
  }

  .blockContena {
    display: flex;
  }

  .listBlock ul {
    list-style-type: none; /* 標準のリストマーカーを無効化 */
  }

  .listBlock li::before {
    content: ">"; /* 「＞」を追加 */
    color: #f99417; /* ピンク色を設定 */
    margin-right: 10px; /* テキストとの間隔 */
    /* 必要に応じて他のスタイリングを追加 */
  }

  .listBlock {
    flex: 1;
    justify-content: center;
    align-items: center;
  }

  .contactbtnLink {
    display: flex; /* Flex コンテナに設定 */
    justify-content: center; /* 水平方向の中央に配置 */
    flex-grow: 1; /* 利用可能なスペースをすべて占める */
    align-items: center; /* 垂直方向の中央に配置 */
  }

  .contactbtnLink a {
    display: flex;
    justify-content: center; /* テキストを中央寄せにする */
    align-items: center;
    margin: 0 auto;
    padding: 1em 2em;
    width: 280px;
    color: #333;
    font-size: 18px;
    font-weight: 700;
    background-color: #f99417;
    border-radius: 50vh;
    position: relative; /* after要素のためのポジショニング基点を設定 */
  }

  .contactbtnLink a::after {
    content: "";
    position: absolute; /* 絶対位置指定 */
    right: 1em; /* 右端からの距離 */
    top: 50%; /* 上端から50%の位置に */
    transform: translateY(-50%) rotate(45deg); /* 中央に来るように調整 */
    width: 5px;
    height: 5px;
    border-top: 3px solid #4d4c7d;
    border-right: 3px solid #4d4c7d;
  }

  .contactbtnLink a:hover {
    text-decoration: none;
    background-color: #bbbbbb;
  }

  .boxDesign {
    padding: 20px;
    //  background-color: rgba(202, 195, 199, 0.4); /* 50%の透明度 */
    position: relative;
  }
  .boxDesign::after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    border-width: 0 20px 20px 0;
    //    border-style: solid;
    //    border-color: #f99417 #f4f3ef #f99417;
    //    box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.15);
  }
}
@media (max-width: 850px) {
  .container {
    width: 100vw;
    margin: 0 auto;
    //    color: #bbbbbb;
  }

  .container img {
    width: 80vw;
    margin: 0 auto;
  }

  .container .textBlock {
    margin: 0 auto;
    margin-top: 20px;
    width: 90%;
    display: flex; /* Flex コンテナに設定 */
    flex-direction: column; /* 縦方向に要素を並べる */
  }
  .container h2 {
    font-size: 50px;
    text-align: center;
  }

  .pBlock p {
    font-size: 20px;
    text-align: center;
  }

  .pBlock1 {
    padding-top: 20px;
  }

  .pBlock2 {
    padding-bottom: 20px;
  }

  .blockContena {
    display: flex;
  }

  .listBlock ul {
    list-style-type: none; /* 標準のリストマーカーを無効化 */
  }

  .listBlock li::before {
    content: ">"; /* 「＞」を追加 */
    color: #f99417; /* ピンク色を設定 */
    margin-right: 10px; /* テキストとの間隔 */
    /* 必要に応じて他のスタイリングを追加 */
  }

  .listBlock {
    flex: 1;
    justify-content: center;
    align-items: center;
  }

  .contactbtnLink {
    display: flex; /* Flex コンテナに設定 */
    justify-content: center; /* 水平方向の中央に配置 */
    flex-grow: 1; /* 利用可能なスペースをすべて占める */
    align-items: center; /* 垂直方向の中央に配置 */
  }

  .contactbtnLink a {
    display: flex;
    justify-content: center; /* テキストを中央寄せにする */
    align-items: center;
    margin: 0 auto;
    margin-top: 20px;
    padding: 1em 2em;
    width: 280px;
    color: #333;
    font-size: 18px;
    font-weight: 700;
    background-color: #f99417;
    border-radius: 50vh;
    position: relative; /* after要素のためのポジショニング基点を設定 */
  }

  .contactbtnLink a::after {
    content: "";
    position: absolute; /* 絶対位置指定 */
    right: 1em; /* 右端からの距離 */
    top: 50%; /* 上端から50%の位置に */
    transform: translateY(-50%) rotate(45deg); /* 中央に来るように調整 */
    width: 5px;
    height: 5px;
    border-top: 3px solid #4d4c7d;
    border-right: 3px solid #4d4c7d;
  }

  .contactbtnLink a:hover {
    text-decoration: none;
    background-color: #bbbbbb;
  }

  .boxDesign {
    padding: 20px;
    //   background-color: rgba(202, 195, 199, 0.4); /* 50%の透明度 */
    position: relative;
  }
  .boxDesign::after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    //   border-width: 0 20px 20px 0;
    //   border-style: solid;
    //   border-color: #f99417 #f4f3ef #f99417;
    //   box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.15);
  }
}
