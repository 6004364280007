.overlay {
  position: fixed; /* オーバーレイを画面に固定 */
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8); /* 半透明の黒色 */
  z-index: 2; /* 他の要素より上に表示 */
  height: 100vh;
}

.continer {
  padding-top: 80px;
  width: calc(100vw - (5.33333vw * 2));
  margin: 0 auto;
}

.svgSmall {
  width: 30%; // SVGの幅を50%に設定
  height: auto; // 高さは自動的に調整
  transform: translateY(-20px);
}

.logTitle {
  display: flex;
}

/* List */
.continer ul {
  counter-reset: index;
  padding: 0;
  max-width: 300px;
}

/* List element */
.continer li {
  counter-increment: index;
  display: flex;
  align-items: center;
  padding: 12px 0;
  box-sizing: border-box;
  color: #fff;
  font-size: 20px;
}

/* Element counter */
.continer li::before {
  content: counters(index, ".", decimal-leading-zero);
  font-size: 1.5rem;
  text-align: right;
  font-weight: bold;
  min-width: 50px;
  padding-right: 12px;
  font-variant-numeric: tabular-nums;
  align-self: flex-start;
  background-image: linear-gradient(to bottom, aquamarine, orangered);
  background-attachment: fixed;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Element separation */
.continer li + li {
  border-top: 1px solid rgba(245, 235, 235, 0.2);
}

.cardContainer {
  position: absolute;
  margin: auto;
  margin-bottom: 20px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 500px;
  height: 500px;
  .card {
    position: absolute;
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 300px;
    height: 200px;
    padding: 20px;
    overflow: hidden;
    background: #d6cfcf;
    border-radius: 10px;
    box-shadow: -2px 2px 0px 0px #202020, -10px 10px 40px 0px rgba(0, 0, 0, 0.3);
    transform: translate(0px, 0px) rotateX(30deg) rotateZ(-20deg);
    z-index: 2;
    transition: all 1s;
    &:hover {
      transform: translate(0px, -20px) rotateX(10deg) rotateZ(-20deg);
      //      box-shadow: -2px 1px 0px 0px #202020, -40px 40px 40px 0 rgba(0, 0, 0, 0.2);
      box-shadow:
      //内側の発光は保留
      //  inset 0 0 60px whitesmoke, inset 20px 0 80px rgb(0, 0, 0),
      //   inset -20px 0 80px rgb(0, 0, 0), inset 20px 0 300px rgb(0, 0, 0),
      //   inset -20px 0 300px rgb(13, 14, 14),
      //     外側の影を設定
        0 0 50px #fff, -10px 0 80px rgb(11, 219, 150), 10px 0 80px rgb(0, 0, 0);
      cursor: pointer;
    }
    h1 {
      color: rgb(0, 0, 0);
      font-size: 24px;
      letter-spacing: 0.1em;
      margin-bottom: 20px;
      font-weight: bold;
      transform: translateX(-25px);

      &:nth-of-type(2) {
        font-size: 16px;
        font-weight: 400;
        text-align: center;
      }
    }
    h2 {
      color: rgb(0, 0, 0);
      font-size: 20px;
      letter-spacing: 0.1em;
      margin-bottom: 20px;
      &:nth-of-type(2) {
        font-size: 16px;
        font-weight: 400;
        text-align: center;
      }
    }
    h3 {
      color: rgb(0, 0, 0);
      font-size: 14px;
      text-transform: uppercase;
      letter-spacing: 0.05em;
      &:nth-of-type(2) {
        font-size: 14px;
        font-weight: 400;
      }
    }
    svg {
    }
    .circle {
      position: absolute;
      margin: auto;
      top: 10%;
      bottom: 0;
      right: -50%;
      width: 300px;
      height: 300px;
      border-radius: 50%;
      background: #64806d;
      z-index: -2;
      &:nth-of-type(2) {
        width: 100px;
        height: 100px;
        top: 90%;
        right: -20%;
        z-index: -1;
        background: rgb(8, 226, 106);
      }
    }
  }
}

@media only screen and (max-width: 780px) {
  .cardContainer {
    .card {
      width: 250px;
      height: 150px;
      animation: hvr 5s infinite ease-in-out;
      transition: none;
      h1 {
        font-size: 18px;
        &:nth-of-type(2) {
          font-size: 14px;
        }
      }
      h2 {
        font-size: 18px;
        &:nth-of-type(2) {
          font-size: 14px;
        }
      }
      h3 {
        font-size: 12px;
        &:nth-of-type(2) {
          font-size: 10px;
        }
      }
      .circle {
        right: -70%;
      }
    }
  }

  @keyframes hvr {
    0% {
      box-shadow: 0 0 50px #fff, -10px 0 80px rgb(11, 219, 150),
        10px 0 80px rgb(0, 0, 0);
      cursor: pointer;
      transform: translate(0px, 0px) rotateX(30deg) rotateZ(-20deg);
    }
    50% {
      transform: translate(0px, -20px) rotateX(30deg) rotateZ(-20deg);
      box-shadow: -2px 1px 0px 0px #202020, -40px 40px 40px 0 rgba(0, 0, 0, 0.2);
    }
    100% {
      box-shadow: 0 0 50px #fff, -10px 0 80px rgb(11, 219, 150),
        10px 0 80px rgb(0, 0, 0);
      cursor: pointer;
      transform: translate(0px, 0px) rotateX(30deg) rotateZ(-20deg);
    }
  }
}
