/* ヘッダー:サイト名（企業）*/
.siteBox {
  padding-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 85px;
}

.site {
  display: flex;
  align-items: center;
}

/* PC用設定ヘッダー　タイトルロゴ*/
.site img {
  width: 85px;
  margin-right: 18px;
}

.site .corp {
  font-size: 48px;
}

@media (max-width: 899px) {
  /* モバイル用設定ヘッダー　タイトルロゴ*/
  .site img {
    width: 50px;
    margin-right: 18px;
  }

  .site .corp {
    font-size: 30px;
  }
}
