/*  contactFrom HERO PC */
.ContactContainer {
  background-color: #000707;
}

.ContactContainer .header {
  position: relative;
  background-color: #121212;
}

.contactFrom {
  color: #ffffff;
  width: 70%;
  margin: 0 auto;
  padding-top: 100px;
}

.contactTitle {
  font-size: 44px;
  margin-bottom: 100px;
}

.contactFrom .form-row {
  display: flex;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #f2f4f5;
}
.contactFrom .form-row:last-child {
  border-bottom: none;
}
.contactFrom .form-label {
  display: flex;
  align-items: center;
  width: 250px;
}
.contactFrom .form-label label {
  font-weight: bold;
}
.contactFrom .form-label span {
  margin-left: 10px;
  padding: 2px 6px;
  border-radius: 3px;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  background-color: #166ab5;
}

/* フォームパーツのデザイン */
.contactFrom input,
.contactFrom textarea {
  background-color: #f2f4f5;
  border: none;
  border-radius: 3px;
  padding: 15px 20px;
  font-size: 16px;
  color: #333;
  flex-grow: 1;
}
.contactFrom input::placeholder,
.contactFrom textarea::placeholder {
  color: #999;
  font-size: 14px;
}
.contactFrom select {
  background-color: #f2f4f5;
  border: none;
  border-radius: 3px;
  padding: 15px 20px;
  font-size: 16px;
  color: #333;
}
.contactFrom button {
  cursor: pointer;
  margin-top: 30px;
  padding: 15px 45px;
  border: none;
  border-radius: 3px;
  color: #fff;
  font-weight: bold;
  background-color: #166ab5;
  width: 180px;
}

.submitStatuText {
  padding-left: 40px;
}

.ContactContainer .footer {
  color: #ffffff;
  height: 300px;
  position: relative; /*配置位置の基準となる指定*/
  background-color: #121212;
  z-index: 30;
  margin-top: 60px;
}
@media (max-width: 899px) {
  .contactFrom {
    color: #ffffff;
    width: 90%;
    margin: 0 auto;
    padding-top: 20px;
  }

  .contactTitle {
    font-size: 44px;
    margin-bottom: 20px;
  }

  .contactFrom .form-row {
    display: block;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #f2f4f5;
  }
  .contactFrom .form-row:last-child {
    border-bottom: none;
  }
  .contactFrom .form-label {
    display: flex;
    align-items: center;
    width: 250px;
    margin-bottom: 10px;
  }
  .contactFrom .form-label label {
    font-weight: bold;
  }
  .contactFrom .form-label span {
    margin-left: 10px;
    padding: 2px 6px;
    border-radius: 3px;
    font-size: 12px;
    font-weight: bold;
    color: #fff;
    background-color: #166ab5;
  }

  /* フォームパーツのデザイン */
  .contactFrom input,
  .contactFrom textarea {
    background-color: #f2f4f5;
    border: none;
    border-radius: 3px;
    padding: 15px 20px;
    font-size: 16px;
    color: #333;
    flex-grow: 1;
    width: 90%;
  }
  .contactFrom input::placeholder,
  .contactFrom textarea::placeholder {
    color: #999;
    font-size: 14px;
  }
  .contactFrom select {
    background-color: #f2f4f5;
    border: none;
    border-radius: 3px;
    padding: 15px 20px;
    font-size: 16px;
    color: #333;
  }
  .contactFrom button {
    cursor: pointer;
    margin-top: 30px;
    padding: 15px 45px;
    border: none;
    border-radius: 3px;
    color: #fff;
    font-weight: bold;
    background-color: #166ab5;
    width: 180px;
  }
  .submitStatuText {
    padding-top: 20px;
    padding-left: 0px;
  }

  .ContactContainer .footer {
    color: #ffffff;
    height: 200px;
    position: relative; /*配置位置の基準となる指定*/
    background-color: #121212;
    z-index: 30;
  }
}
