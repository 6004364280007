@media (min-width: 900px) {
  /*  about HERO PC */
  .abouthero {
    color: #ffffff;
  }

  .abouthero .container .heroTitle {
    display: flex;
    align-items: center;
    font-size: 38px;
    height: 300px;
    width: calc(100vw - (5.33333vw * 2));
    margin: 0 auto;
  }

  .abouthero .container .heroTitle h2 {
    border-bottom: solid 3px wheat;
  }

  .abouthero .container .heroTitle p {
    margin-left: 50px;
    font-size: 26px;
  }

  .abouthero .container .heroDesc {
    font-size: 20px;
    line-height: 2.6;
    margin-left: 20px;
    text-align: center;
    margin-bottom: 200px;
  }

  .abouthero .container .aboutImgContiner {
    display: flex;
    width: calc(100vw - (5.33333vw * 2));
    margin: 0 auto;
    margin-bottom: 200px;
    justify-content: space-evenly;
  }

  .abouthero .container .aboutImgContiner .aboutDesc {
    font-size: 20px;
    line-height: 2.6;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 40px;
  }
  .abouthero .container .aboutImgContiner .imgSide {
    width: 35vw;
  }
}

@media (max-width: 899px) {
  /*  about HERO PC */
  .abouthero {
    color: #ffffff;
  }

  .abouthero .container .heroTitle {
    display: flex;
    align-items: center;
    font-size: 20px;
    height: 100px;
    width: calc(100vw - (5.33333vw * 2));
    margin: 0 auto;
  }

  .abouthero .container .heroTitle h2 {
    border-bottom: solid 3px wheat;
  }

  .abouthero .container .heroTitle p {
    margin-left: 50px;
    font-size: 16px;
  }

  .abouthero .container .heroDesc {
    padding-top: 20px;
    font-size: 16px;
    line-height: 2.5;
    margin-left: 20px;
    text-align: center;
    margin-bottom: 50px;
    width: calc(100vw - (5.33333vw * 2));
  }

  .abouthero .container .aboutImgContiner {
    width: calc(100vw - (5.33333vw * 2));
    margin: 0 auto;
    margin-bottom: 40px;
  }
  .abouthero .container .aboutImgContiner .aboutDesc {
    font-size: 16px;
    line-height: 2.5;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
  }
  .abouthero .container .aboutImgContiner .imgSide {
    margin: 0 auto;
    width: 60vw;
  }
}
