@media (min-width: 900px) {
  .outPutContiner {
    //    height: 85vh;
    padding-bottom: 30px;
    background-color: #f4f3ef;
  }

  .siteArea {
    width: calc(100vw - (5.33333vw * 2));
    margin: 0 auto;
  }
  .titletext h2 {
    font-size: 30px;
    padding-left: 20px;
    padding-top: 40px;
  }
}

.textDesc {
  padding-left: 20px;
  padding-top: 40px;
  line-height: 1.9;
}

.btnGroup {
  margin-top: 20px;
}

.fileName {
  padding-top: 20px;
  padding-bottom: 20px;
}

.dropArea {
  padding-top: 10px;
  padding-bottom: 50px;
}

.outputFiles {
  padding-bottom: 50px;
}

.buttonMargin button {
  margin-right: 10px;
}

@media (max-width: 899px) {
  .outPutContiner {
    //    height: 85vh;
    padding-bottom: 30px;
    background-color: #f4f3ef;
  }

  .siteArea {
    width: calc(100vw - (5.33333vw * 2));
    margin: 0 auto;
  }
  .titletext h2 {
    font-size: 30px;
    padding-left: 20px;
    padding-top: 40px;
  }
}

.textDesc {
  padding-left: 20px;
  padding-top: 40px;
  line-height: 1.9;
}

.btnGroup {
  margin-top: 20px;
}

.fileName {
  padding-top: 20px;
  padding-bottom: 20px;
}

.dropArea {
  padding-top: 10px;
  padding-bottom: 50px;
}

.outputFiles {
  padding-bottom: 50px;
}

.buttonMargin button {
  margin-right: 10px;
}
