@font-face {
  font-family: "kinto-sans";
  src: url("./font/KintoSans-Regular.woff2") format("woff2");
}

/*  基本 */
body {
  overflow-x: hidden;
  font-family: "kinto-sans", "Noto Sans JP", sans-serif;
  // background-color: #f4f3ef;
  background-color: var(
    --bg-gradient-color,
    #f4f3ef
  ); /* defaultColorは初期色 */
}
div {
  //  box-sizing: border-box;
}

input,
textarea,
select　 {
  font-family: "kinto-sans", "Noto Sans JP", sans-serif;
}

/* リセット / ノーマライズ / サニタイズ */
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
figure {
  margin: 0;
  padding: 0;
  font-size: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  opacity: 0.8;
}

.sr-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  width: 1px;
}
