@media (min-width: 851px) {
  .contents {
    overflow: hidden;
    position: relative;
    .wrapper {
      width: 200%;
      height: 100vh;
      display: flex;
      flex-wrap: nowrap;
      will-change: auto;
    }
  }

  .fixedHeader {
    padding-top: 78px;
  }

  .panelContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }
}
@media (max-width: 850px) {
  .contents {
    overflow: hidden;
    position: relative;
    .wrapper {
      width: 200%;
      height: 100vh;
      display: flex;
      flex-wrap: nowrap;
      will-change: auto;
    }
  }

  .fixedHeader {
    padding-top: 78px;
  }

  .panelContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }
}
