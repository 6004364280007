.serviceContainer {
  background-color: #000707;
}

/*  header PC */
.serviceContainer .header {
  background-color: #121212;
  position: relative;
}

/*  about HERO PC */
.servicehero {
  color: #ffffff;
}
/*
.aboutImg {
  background: url(../../public/ginga.png);
  background-size: cover;
  background-repeat: no-repeat;
  height: 872px;
  background-position: 400px, 400px, 400px, 400px;
}
*/
/*
.servicehero .container {
    width: calc(100vw - (5.33333vw * 2));
  margin: 0 auto;
  margin-bottom: 40px;
  
} 
*/

.servicehero .container h2 {
  display: flex;
  align-items: center;
  font-size: 44px;
  height: 300px;
  width: calc(100vw - (5.33333vw * 2));
  margin: 0 auto;
}

.servicehero .container .moonImg {
  background: url(../../public/moonHero.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 387px;
}
.servicehero .container .moonDesc {
  width: calc(100vw - (12.5vw * 2));
  margin: 0 auto;
  padding-top: 70px;
  font-size: 26px;
  line-height: 2;
  padding-bottom: 20px;
  text-align: center;
}

.moonBtnLink {
  font-size: 30px;
  padding-left: 0px;
  margin-bottom: 100px;
}
.moonBtnLink a {
  display: flex;
  justify-content: center; /* テキストを中央寄せにする */
  align-items: center;
  margin: 0 auto; /* 左端に寄せるために変更 */
  padding: 1em 2em;
  width: 240px;
  color: #333;
  font-size: 18px;
  font-weight: 700;
  background-color: #cccccc;
  border-radius: 50vh;
  position: relative; /* after要素のためのポジショニング基点を設定 */
}

.moonBtnLink a::after {
  content: "";
  position: absolute; /* 絶対位置指定 */
  right: 1em; /* 右端からの距離 */
  top: 50%; /* 上端から50%の位置に */
  transform: translateY(-50%) rotate(45deg); /* 中央に来るように調整 */
  width: 5px;
  height: 5px;
  border-top: 3px solid #333333;
  border-right: 3px solid #333333;
}

.moonBtnLink a:hover {
  text-decoration: none;
  background-color: #bbbbbb;
}

.servicehero .container .seviceSystemheroColor {
  margin-bottom: 50px;
  background-color: #121212;
  margin-bottom: 100px;
}

.servicehero .container .seviceSystemhero {
  width: calc(100vw - (17.5vw * 2));
  margin: 0 auto;
  display: flex;
  padding-top: 30px;
}

.servicehero .container .seviceSystemhero h2 {
  align-items: start;
}

.servicehero .container .seviceSystemheroImg {
  background: url(../../public/contact_img.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  width: calc(100vw - (17.5vw * 2));
  height: 300px;
}

.servicehero .container .seviceSystemherodDesc {
  width: calc(100vw - (17.5vw * 2));
  margin: 0 auto;
  font-size: 20px;
}

.servicehero .container .seviceSystemherodDesc .p1 {
  padding-top: 40px;
}

.servicehero .container .seviceSystemherodDesc .p2 {
  margin-top: 20px;
  padding-bottom: 50px;
}

.contactBtnLink {
  font-size: 30px;
  padding-left: 0px;
  padding-bottom: 60px;
}
.contactBtnLink a {
  display: flex;
  justify-content: center; /* テキストを中央寄せにする */
  align-items: center;
  margin: 0 auto; /* 左端に寄せるために変更 */
  padding: 1em 2em;
  width: 240px;
  color: #333;
  font-size: 18px;
  font-weight: 700;
  background-color: #cccccc;
  border-radius: 50vh;
  position: relative; /* after要素のためのポジショニング基点を設定 */
}

.contactBtnLink a::after {
  content: "";
  position: absolute; /* 絶対位置指定 */
  right: 1em; /* 右端からの距離 */
  top: 50%; /* 上端から50%の位置に */
  transform: translateY(-50%) rotate(45deg); /* 中央に来るように調整 */
  width: 5px;
  height: 5px;
  border-top: 3px solid #333333;
  border-right: 3px solid #333333;
}

.contactBtnLink a:hover {
  text-decoration: none;
  background-color: #bbbbbb;
}

.serviceContainer .footer {
  color: #ffffff;
  height: 300px;
  position: relative; /*配置位置の基準となる指定*/
  z-index: 30;
}

@media (max-width: 899px) {
  /*  about HERO PC */
  .servicehero {
    color: #ffffff;
  }

  .servicehero .container h2 {
    display: flex;
    align-items: center;
    font-size: 26px;
    height: 100px;
    width: calc(100vw - (5.33333vw * 2));
    margin: 0 auto;
  }

  .servicehero .container .moonImg {
    background: url(../../public/moonHero.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 240px;
  }
  .servicehero .container .moonDesc {
    width: calc(100vw - (5.5vw * 2));
    margin: 0 auto;
    padding-top: 10px;
    font-size: 18px;
    line-height: 1.5;
    padding-bottom: 30px;
    text-align: center;
  }

  .servicehero .container .seviceSystemhero {
    width: calc(100vw - (5.5vw * 2));
    margin: 0 auto;
    padding-top: 30px;
    display: block;
  }

  .servicehero .container .seviceSystemhero h2 {
    align-items: start;
    height: 20px;
    font-size: 20px;
  }

  .servicehero .container .seviceSystemheroImg {
    background: url(../../public/contact_img.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right;
    width: calc(100vw - (17.5vw * 2));
    height: 200px;
  }

  .servicehero .container .seviceSystemherodDesc {
    width: calc(100vw - (17.5vw * 2));
    margin: 0 auto;
    font-size: 20px;
  }

  .servicehero .container .seviceSystemherodDesc .p1 {
    padding-top: 20px;
    font-size: 20px;
  }

  .servicehero .container .seviceSystemherodDesc .p2 {
    margin-top: 20px;
    padding-bottom: 50px;
    font-size: 20px;
  }

  .serviceContainer .footer {
    color: #ffffff;
    height: 200px;
    position: relative; /*配置位置の基準となる指定*/
    z-index: 30;
  }
}
