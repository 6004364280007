@media (min-width: 851px) {
  .container {
    width: calc(100vw - (5.33333vw * 2));
    margin: 0 auto;
    justify-content: space-around;
    height: 500px;
    padding: 20px;
    display: flex;
  }

  .container2 {
    width: calc(100vw - (5.33333vw * 2));
    margin: 0 auto;
    justify-content: space-around;
    height: 500px;
    padding: 20px;
    display: flex;
  }

  .card {
    margin-top: 40px;
    width: 450px;
    margin-right: 20px;
  }

  .servicebtnLink a {
    display: flex;
    justify-content: center; /* テキストを中央寄せにする */
    align-items: center;
    margin: 0 auto;
    padding: 1em 2em;
    width: 280px;
    color: #333;
    font-size: 18px;
    font-weight: 700;
    background-color: #f99417;
    border-radius: 50vh;
    position: relative; /* after要素のためのポジショニング基点を設定 */
  }

  .servicebtnLink a::after {
    content: "";
    position: absolute; /* 絶対位置指定 */
    right: 1em; /* 右端からの距離 */
    top: 50%; /* 上端から50%の位置に */
    transform: translateY(-50%) rotate(45deg); /* 中央に来るように調整 */
    width: 5px;
    height: 5px;
    border-top: 3px solid #4d4c7d;
    border-right: 3px solid #4d4c7d;
  }

  .servicebtnLink a:hover {
    text-decoration: none;
    background-color: #bbbbbb;
  }

  .boxDesign {
    padding: 20px;
    //  background-color: rgba(254, 217, 237, 0.5); /* 50%の透明度 */
    position: relative;
  }
  .boxDesign::after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    //   border-width: 0 20px 20px 0;
    //   border-style: solid;
    //   border-color: #f99417 #f4f3ef #f99417;
    //   box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.15);
  }
}
@media (max-width: 850px) {
  .container {
    width: 90vw;
    margin: 0 auto;
  }

  .container2 {
    width: 90vw;
    margin: 0 auto;
  }

  .card {
    margin-top: 40px;
    //    width: 450px;
  }

  .servicebtnLink {
    display: flex; /* Flex コンテナに設定 */
    justify-content: center; /* 水平方向の中央に配置 */
    flex-grow: 1; /* 利用可能なスペースをすべて占める */
    align-items: center; /* 垂直方向の中央に配置 */
  }

  .servicebtnLink a {
    display: flex;
    justify-content: center; /* テキストを中央寄せにする */
    align-items: center;
    margin: 0 auto;
    margin-top: 20px;
    padding: 1em 2em;
    width: 280px;
    color: #333;
    font-size: 18px;
    font-weight: 700;
    background-color: #f99417;
    border-radius: 50vh;
    position: relative; /* after要素のためのポジショニング基点を設定 */
  }

  .servicebtnLink a::after {
    content: "";
    position: absolute; /* 絶対位置指定 */
    right: 1em; /* 右端からの距離 */
    top: 50%; /* 上端から50%の位置に */
    transform: translateY(-50%) rotate(45deg); /* 中央に来るように調整 */
    width: 5px;
    height: 5px;
    border-top: 3px solid #4d4c7d;
    border-right: 3px solid #4d4c7d;
  }

  .servicebtnLink a:hover {
    text-decoration: none;
    background-color: #bbbbbb;
  }
}
