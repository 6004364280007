.cursor {
  position: fixed;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  background-color: rgba(123, 183, 226, 0.8);
  border-radius: 50%;
  pointer-events: none; /* クリックの対象外にする */
  transform: translate(-50%, -50%); /* 中心に合わせる */
  transition: transform 0.05s ease-out; /* 滑らかな追随 */
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(40px); /* 下から上への移動の開始点 */
  }
  to {
    opacity: 1;
    transform: translateY(0); /* 最終的な位置 */
  }
}
@keyframes expandCircle {
  to {
    clip-path: circle(100% at 50% 50%); /* 最終状態：円の半径を100%に設定 */
  }
}

@media (min-width: 851px) {
  .hero p {
    position: absolute;
    color: black;
    z-index: 1;
    //  font-family: kinto-sans, "Noto Sans JP", sans-serif;
    font-weight: bold; /* 太字にする */
    opacity: 1;
    //  opacity: 0;
  }

  .hero .p1 {
    line-height: 2;
    font-size: 30px;
    top: calc(
      (100vh - 70px) * 0.3
    ); /* ビューポートの高さからヘッダーの高さを引いた値の30% */
    left: 100vw * 0.1;
    //  animation: fadeInUp 0.7s ease forwards; /* アニメーションの適用 */
  }

  .hero .p2 {
    line-height: 2;
    font-size: 30px;
    top: calc(
      (100vh - 70px) * 0.4
    ); /* ビューポートの高さからヘッダーの高さを引いた値の30% */
    left: 100vw * 0.1;
    //  animation: fadeInUp 0.7s ease 0.7s forwards; /* 0.7秒の遅延を設定 */
  }

  .hero #animeLogo img {
    line-height: 2;
    position: absolute;
    top: calc(
      (100vh - 70px) * 0.5
    ); /* ビューポートの高さからヘッダーの高さを引いた値の30% */
    left: 100vw * 0.1;
    color: black;
    z-index: 1;
    //  font-family: kinto-sans, "Noto Sans JP", sans-serif;
    //  animation: fadeInUp 0.7s ease 1.4s forwards; /* 0.7秒の遅延を設定 */
  }

  .WebGL canvas {
    width: 100vw; /* 画像の最終的な大きさ */
    height: 100vh;
    clip-path: circle(0% at 50% 50%); /* 初期状態：円の半径を0%に設定 */
    animation: expandCircle 10s forwards; /* アニメーションの適用 */
  }
}

@media (max-width: 850px) {
  .hero p {
    position: absolute;
    color: black;
    z-index: 1;
    //  font-family: kinto-sans, "Noto Sans JP", sans-serif;
    font-weight: bold; /* 太字にする */
    opacity: 1;
    //  opacity: 0;
  }

  .hero .p1 {
    line-height: 2;
    font-size: 24px;
    top: calc(
      (100vh - 70px) * 0.3
    ); /* ビューポートの高さからヘッダーの高さを引いた値の30% */
    left: 100vw * 0.1;
    //  animation: fadeInUp 0.7s ease forwards; /* アニメーションの適用 */
  }

  .hero .p2 {
    line-height: 2;
    font-size: 24px;
    top: calc(
      (100vh - 70px) * 0.4
    ); /* ビューポートの高さからヘッダーの高さを引いた値の30% */
    left: 100vw * 0.1;
    //  animation: fadeInUp 0.7s ease 0.7s forwards; /* 0.7秒の遅延を設定 */
  }

  .hero #animeLogo img {
    line-height: 2;
    position: absolute;
    top: calc(
      (100vh - 70px) * 0.5
    ); /* ビューポートの高さからヘッダーの高さを引いた値の30% */
    left: 100vw * 0.1;
    width: 70vw;
    color: black;
    z-index: 1;
    //  font-family: kinto-sans, "Noto Sans JP", sans-serif;
    //  animation: fadeInUp 0.7s ease 1.4s forwards; /* 0.7秒の遅延を設定 */
  }

  .WebGL canvas {
    width: 100vw; /* 画像の最終的な大きさ */
    height: 100vh;
    clip-path: circle(0% at 50% 50%); /* 初期状態：円の半径を0%に設定 */
    animation: expandCircle 10s forwards; /* アニメーションの適用 */
  }
}
