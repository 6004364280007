:root {
  --black: #000000;
  --white: #ffffff;
  --pink: #df7a99;
  --graphite: #5f555d;
  --gray: #afaaae;
  --lightgray: #d7d5d7;
  --z-header: 10;
  --z-modal: 100;
  --main-color: #000;
  --ease-out-sine: cubic-bezier(0.39, 0.575, 0.565, 1);
  --transition: 0.5s var(--ease-out-sine);
  --header-h: 78;
}

.header {
  position: relative;
  -webkit-backdrop-filter: blur(7px);
  backdrop-filter: blur(7px);
  //  background-color: #f4f3ef;
  background-color: rgba(
    244,
    243,
    239,
    0.5
  ); /* アルファ値を0.5に設定して半透明に */
  height: calc(var(--header-h) * 1em / 16);
  left: 0;
  //  position: fixed;
  text-align: left;
  top: 0;
  transition: height 0.3s;
  width: 100%;
  z-index: 1001;
}

.fixedHeader {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1001;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 74px;
}

.srOnly {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.headerLogo {
  height: 100%;
  padding-left: 20px;
  transform: translateY(20%);
}

.headerLogo h2 {
  padding-left: 50px;
  font-size: 30px;
  transform: translateY(-55%);
  font-family: "kinto-sans", "Noto Sans JP", sans-serif;
  font-weight: bold;
}

.headerLogoTarget {
  --w: 77;
  display: block;
  overflow: inherit;
  width: calc(var(--w) * 1em / 16);
}

.headerLogoTarget svg {
  fill: currentColor;
  height: auto;
}

@media (max-width: 900px) {
  .headerNav {
    display: none;
  }

  /* ナビゲーションボタン */
  .navButton {
    box-sizing: content-box;
    padding: 0;
    outline: none;
    border: none;
    background: none;
    width: 20px;
    height: 14px;
    cursor: pointer;
    color: #333333;
    padding-right: 20px;
  }

  .navButton::before,
  .navButton::after {
    content: "";
    display: block;
    height: 2px;
    background-color: currentColor;
    transform: translateY(5px);
    transition: 0.3s ease-in-out;
  }

  .navButton::before {
    transform: translateY(-5px);
    box-shadow: 0 6px currentColor;
  }

  /* ナビゲーションボタン（閉じるボタン） */
  .open .navButton {
    color: #333333;
  }

  .open .navButton::before {
    transform: translateY(1px) rotate(45deg);
    box-shadow: none;
  }

  .open .navButton::after {
    transform: translateY(-1px) rotate(-45deg);
  }
}

@media (min-width: 901px) {
  .headerNav {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: right;
    position: relative;
  }

  /* ナビゲーションボタン */
  .navButton {
    box-sizing: content-box;
    padding: 0;
    outline: none;
    border: none;
    background: none;
    width: 20px;
    height: 14px;
    cursor: pointer;
    color: #333333;
    padding-right: 20px;
  }

  .navButton::before,
  .navButton::after {
    content: "";
    display: block;
    height: 2px;
    background-color: currentColor;
    transform: translateY(5px);
    transition: 0.3s ease-in-out;
  }

  .navButton::before {
    transform: translateY(-5px);
    box-shadow: 0 6px currentColor;
  }

  /* ナビゲーションボタン（閉じるボタン） */
  .open .navButton {
    color: #333333;
  }

  .open .navButton::before {
    transform: translateY(1px) rotate(45deg);
    box-shadow: none;
  }

  .open .navButton::after {
    transform: translateY(-1px) rotate(-45deg);
  }

  .nav ul {
    display: flex;
    align-items: center;
    list-style: none;
    color: #333333;
  }

  .nav li:not(:last-child) {
    margin-right: 23px;
  }

  .nav .btn {
    width: 148px;
    border-color: #cdd6dd;
  }
}

.headerNavList {
  display: flex;
  list-style: none;
}

.uFonten {
  font-family: "kinto-sans", "Noto Sans JP", sans-serif;
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
}

.headerNavListItem {
  --mlr: 32;
  margin: 0 calc(var(--mlr) * 1em / 16);
}

.headerNavListItem .target {
  font-size: 0.875em;
  letter-spacing: 0.1em;
}

.uTargetfilter:any-link {
  -webkit-background-clip: text;
  background-clip: text;
  background-image: linear-gradient(
    to right,
    #6b6b6b 45%,
    var(--main-color) 55%
  );
  background-position: 100% 50%;
  background-repeat: no-repeat;
  background-size: 220% 100%;
  color: #0000;
  display: block;
  transition: 0.4s var(--ease-out-sine);
}

@media (any-hover: hover) {
  .UTarget:where(:any-link, :enabled) {
    transition: opacity var(--transition);
  }

  .UTarget:where(:any-link, :enabled):hover {
    opacity: 0.6;
  }

  .uTargetfilter:any-link {
    -webkit-background-clip: text;
    background-clip: text;
    background-image: linear-gradient(
      to right,
      #6b6b6b 45%,
      var(--main-color) 55%
    );
    background-position: 100% 50%;
    background-repeat: no-repeat;
    background-size: 220% 100%;
    color: #0000;
    display: block;
    transition: 0.4s var(--ease-out-sine);
  }

  .uTargetfilter:any-link:hover {
    animation-duration: 0.6s;
    animation-fill-mode: forwards;
    animation-name: filteranim;
    animation-timing-function: var(--ease-out-sine);
    background-position: 0 50%;
  }

  @keyframes filteranim {
    0% {
      filter: brightness(1);
    }

    10% {
      filter: brightness(2.5);
    }

    to {
      filter: brightness(1.4);
    }
  }
}

.cmHNavArrowTarget {
  --left: 48;
  align-items: center;
  display: flex;
  height: 100%;
  left: calc(var(--left) * 1em / 16);
  position: absolute;
  top: 0;
  transition: opacity 0.45s var(--ease-out-sine),
    visibility 0.45s var(--ease-out-sine);
  --w: 22;
  width: calc(var(--w) * 1em / 16);
  display: none;
}

.cmHNavArrowTarget svg {
  fill: #626054;
}

.cmHNavInstagram {
  --right: 48;
  align-items: center;
  display: flex;
  height: 100%;
  position: absolute;
  right: calc(var(--right) * 1em / 16);
  top: 0;
  --w: 20;
  width: calc(var(--w) * 1em / 16);
}

.cmHNavInstagramTarget {
  --w: 20;
  display: block;
  width: calc(var(--w) * 1em / 16);
}

:where(svg) {
  fill: currentColor;
  max-width: 100%;
  overflow: inherit;
}

:where(img, picture, svg, video) {
  block-size: auto;
  max-inline-size: 100%;
}

:where(img, svg, video, canvas, audio, iframe, embed, object) {
  display: block;
}

:where(a) {
  background-color: initial;
  border: 0;
  color: currentColor;
  text-decoration: none;
}
