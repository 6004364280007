.nav ul {
  font-size: 24px;
  display: flex;
  list-style-type: none;
  gap: 40px;
}

@media (max-width: 899px) {
  .nav {
    font-size: 24px;
    display: none;
  }
}
