.loadingContainer {
  position: fixed; /* 画面全体に固定 */
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #f4f3ef; /* 不透明の背景色 */
  z-index: 99999999; /* 他の要素より前面に表示 */

  display: flex;
  justify-content: center;
  align-items: center;
}

#animeLogo {
  color: black;
}

.loadingContainer {
  width: 103vw;
}

.curtain {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #424242;
}
