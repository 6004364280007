.moonPageContainer {
  background-color: #000707;
}

/*  HERO PC */
.moonhero {
  width: calc(100vw - (5.33333vw * 2));
  margin: 0 auto;
  color: #ffffff;
  padding-top: 160px;
  margin-bottom: 40px;
}

.moonhero .container .moonHeroImg {
  background: url(../../public/moonHero.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 387px;
}

/* dlButton */
.moonhero .dlButton a {
  font-size: 26px;
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0 auto;
  margin-top: 76px;
  max-width: 240px;
  padding: 10px 25px;
  color: #fff;
  transition: 0.3s ease-in-out;
  font-weight: 600;
  background: hwb(147 5% 25%);
  filter: drop-shadow(0px 2px 4px #ccc);
  border-radius: 3px;
  border-radius: 50px;
  overflow: hidden;
}
.moonhero .dlButton a:before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: -100%;
  background-image: linear-gradient(
    130deg,
    rgba(255, 255, 255, 0) 25%,
    rgba(255, 255, 255, 0.3) 50%,
    rgba(255, 255, 255, 0) 55%
  );
  -webkit-transition: 0.5s;
  transition: 0.6s;
}
.moonhero .dlButton a:hover:before {
  left: 100%;
}
.moonhero .dlButton a:after {
  position: absolute;
  top: 50%;
  right: 20px;
  transition: 0.2s ease-in-out;
  font-weight: 900;
  transform: translateY(-50%);
}

.moonhero .container p.desc {
  font-size: 20px;
  text-align: center;
  padding-top: 10px;
  line-height: 2;
}

.moonhero .container .subP p {
  font-size: 30px;
  padding-top: 0px;
  padding-left: 200px;
  line-height: 2;
}

.moonhero .container h2 {
  position: relative;
  z-index: 30;
  font-size: 60px;
  padding-left: 200px;
  padding-top: 100px;
  line-height: 2;
}

.moonDescZone {
  width: calc(100vw - (5.33333vw * 2));
  margin: 0 auto;
  color: #ffffff;
  padding-top: 30px;
  margin-bottom: 40px;
}

.moonDescZone .moonDesc1 {
  padding-top: 100px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
  justify-content: space-around;
}

.moonDescZone .moonDesc1Img {
  background: url(../../public/capture3.gif);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: start;
  height: 392px;
  width: 600px;
}

.moonDescZone .moonDesc1 .moonDesc1Txt {
  padding-left: 30px;
  max-width: 800px;
}

.moonDescZone .moonDesc1 .moonDesc1Txt .p1 {
  font-size: 30px;
}

.moonDescZone .moonDesc1 .moonDesc1Txt .p2 {
  font-size: 24px;
  padding-top: 50px;
}

.moonDescZone .moonDesc1Txt .p3 {
  font-size: 24px;
  padding-top: 40px;
}

.moonDescZone .moonDesc1Txt .p4 {
  font-size: 24px;
  padding-top: 40px;
}

.moonDescZone .moonDesc2Img {
  background: url(../../public/capture6.gif);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: start;
  height: 392px;
  width: 600px;
}

.moonDescZone .moonDesc3 {
  font-size: 24px;
  padding-top: 100px;
}

.moonDescZone .moonDesc3 .p1 {
  font-size: 30px;
  padding-top: 40px;
  text-align: center;
}
.moonDescZone .moonDesc3 .p2 {
  font-size: 24px;
  padding-top: 40px;
  text-align: center;
}
.moonDescZone .moonDesc3 .p3 {
  font-size: 24px;
  padding-top: 20px;
  text-align: center;
}
.moonDescZone .moonDesc3 .p4 {
  font-size: 24px;
  padding-top: 20px;
  padding-bottom: 50px;
  text-align: center;
}

.moonPageContainer .footer {
  background-color: #121212;
}

@media (max-width: 899px) {
  /*  HERO PC */
  .moonhero {
    padding-top: 20px;
    margin-bottom: 20px;
  }

  .moonhero .container .moonHeroImg {
    height: 220px;
  }

  /* dlButton */
  .moonhero .dlButton a {
    margin-top: 20px;
    max-width: 240px;
    padding: 10px 25px;
    color: #fff;
    transition: 0.3s ease-in-out;
    font-weight: 600;
    background: hwb(147 5% 25%);
    filter: drop-shadow(0px 2px 4px #ccc);
    border-radius: 3px;
    border-radius: 50px;
    overflow: hidden;
  }
  .moonhero .dlButton a:before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: -100%;
    background-image: linear-gradient(
      130deg,
      rgba(255, 255, 255, 0) 25%,
      rgba(255, 255, 255, 0.3) 50%,
      rgba(255, 255, 255, 0) 55%
    );
    -webkit-transition: 0.5s;
    transition: 0.6s;
  }
  .moonhero .dlButton a:hover:before {
    left: 100%;
  }
  .moonhero .dlButton a:after {
    position: absolute;
    top: 50%;
    right: 20px;
    transition: 0.2s ease-in-out;
    font-weight: 900;
    transform: translateY(-50%);
  }

  .moonhero .container p.desc {
    font-size: 20px;
    text-align: center;
    padding-top: 10px;
    line-height: 2;
  }

  .moonhero .container .subP p {
    font-size: 30px;
    padding-top: 0px;
    padding-left: 200px;
    line-height: 2;
  }

  .moonhero .container h2 {
    position: relative;
    z-index: 30;
    font-size: 60px;
    padding-left: 200px;
    padding-top: 100px;
    line-height: 2;
  }

  .moonDescZone {
    width: calc(100vw - (5.33333vw * 2));
    margin: 0 auto;
    color: #ffffff;
    padding-top: 20px;
    margin-bottom: 40px;
  }

  .moonDescZone .moonDesc1 {
    padding-top: 20px;
    display: -webkit-flex;
    display: block;
    -webkit-justify-content: space-around;
    justify-content: space-around;
  }

  .moonDescZone .moonDesc1Img {
    background: url(../../public/capture3.gif);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 200px;
    width: 80%;
  }

  .moonDescZone .moonDesc1 .moonDesc1Txt {
    padding-left: 10px;
    max-width: 800px;
  }

  .moonDescZone .moonDesc1 .moonDesc1Txt .p1 {
    font-size: 26px;
  }

  .moonDescZone .moonDesc1 .moonDesc1Txt .p2 {
    font-size: 20px;
    padding-top: 20px;
  }

  .moonDescZone .moonDesc1Txt .p3 {
    font-size: 20px;
    padding-top: 20px;
  }

  .moonDescZone .moonDesc1Txt .p4 {
    font-size: 20px;
    padding-top: 20px;
  }

  .moonDescZone .moonDesc2Img {
    background: url(../../public/capture6.gif);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 200px;
    width: 80%;
  }

  .moonDescZone .moonDesc3 {
    font-size: 24px;
    padding-top: 20px;
  }

  .moonDescZone .moonDesc3 .p1 {
    font-size: 26px;
    padding-top: 40px;
    text-align: center;
  }
  .moonDescZone .moonDesc3 .p2 {
    font-size: 20px;
    padding-top: 40px;
    text-align: center;
  }
  .moonDescZone .moonDesc3 .p3 {
    font-size: 20px;
    padding-top: 20px;
    text-align: center;
  }
  .moonDescZone .moonDesc3 .p4 {
    font-size: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
  }

  .moonPageContainer .footer {
    background-color: #121212;
  }
}
